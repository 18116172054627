.App-body {
  text-align: center;
  background-size: 100vw 100vh; /* Cover the entire background; use 'contain' for different effect */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed; /* Keeps the background image fixed during scroll */
  background-position: center bottom;
  min-height: 100vh;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-size: 90vw 90vh;
  background-repeat: no-repeat; 
  background-position: center 90%;
  
  
  
}

.background-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

body {
  overflow: hidden;
  font-family: "Nunito", serif;
  font-size: 48px;
  text-shadow: 4px 4px 4px #aaa;
  
}

@media (max-width: 600px) {
  .textField,
  .button {
    width: 100%; /* Full width on small screens */
  }

  .App-logo {
    min-width: 100px; /* Minimum width to prevent logo from becoming too small */
    min-height: 100px; /* Minimum height to match the width and maintain aspect ratio */
  }

  .App {
    background-position: center 30%; /* Adjust the vertical position for better visibility */
    background-size: 140vw 140vh; 
  }
}

@media (min-width: 601px) {
  .textField {
    width: 400px; /* Fixed width on larger screens */
  }
  .button {
    width: 500px; /* Fixed width on larger screens */
  }
}

